<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft p-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-7">
                <div class="text-white">
                  <h2 class="text-white">{{ nameApp }}</h2>
                  <h6 class="text-white">Ingresa tu nueva contraseña</h6>
                </div>
              </div>
              <div class="col-5 align-self-end mb-3">
                <img :src="logoApp" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="p-2">
              <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                <b-form-group label="Contraseña" class="has-float-label mb-4">
                  <b-form-input
                    type="password"
                    v-model="$v.form.password.$model"
                    :state="!$v.form.password.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.password.required"
                    >Por favor ingresa tu Contraseña</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback
                    v-else-if="!$v.form.password.minLength || !$v.form.password.maxLength"
                    >Debe tener al menos 4 caracteres</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group label="Confirmar Contraseña" class="has-float-label mb-4">
                  <b-form-input
                    type="password"
                    v-model="$v.form.passwordAgain.$model"
                    :state="!$v.form.passwordAgain.$error"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.passwordAgain.required"
                    >Por favor confirma tu Contraseña</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-else-if="!$v.form.passwordAgain.sameAsPassword"
                    >Las contraseñas no coinciden</b-form-invalid-feedback
                  >
                </b-form-group>

                <div class="col-12 d-grid">
                  <b-button class="mt-2 btn-block" variant="outline-dark" type="submit">
                    <b-spinner v-if="loading" class="mx-2" small></b-spinner>
                    Recuperar contraseña
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            ¿Ya tienes una cuenta?
            <router-link tag="a" to="/login" class="fw-medium text-primary"
              >Iniciar sesión</router-link
            >
          </p>
          <Footer></Footer>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '../../layouts/auth'
import appConfig from '@/app.config'
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { nameApp, logoApp } from '@/constants/config'
import Footer from './components/footer.vue'
const { required, maxLength, minLength, sameAs } = require('vuelidate/lib/validators')

/**
 * Forgot Password component
 */
export default {
  page: {
    title: 'Recuperar contraseña',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    Footer
  },
  data() {
    return {
      nameApp,
      logoApp,
      form: {
        password: '',
        passwordAgain: '',
        token: null,
        code: null
      },
      loading: false
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4)
      },
      passwordAgain: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  async mounted() {
    await this.sendValidateTokenResetPassword()
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      resetPasswordSuccess: 'user/resetPasswordSuccess'
    })
  },
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword',
      validateTokenResetPassword: 'user/validateTokenResetPassword'
    }),
    async sendValidateTokenResetPassword() {
      const resp = await this.validateTokenResetPassword({
        code: this.$route.params.code,
        token: this.$route.params.token
      })
      if (resp.status == 401) {
        this.$router.push('/login')
      } else {
        this.form.code = this.$route.params.code
        this.form.token = this.$route.params.token
      }
    },
    async formSubmit() {
      this.$v.form.$touch()
      this.loading = true
      if (!this.$v.form.$anyError) {
        const response = await this.resetPassword({
          password: this.form.password,
          uidb64: this.form.code,
          token: this.form.token
        })
        if (response.status == 201) {
          this.loading = false
          this.$router.push('/login')
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" module></style>
